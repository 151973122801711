import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = { class: "text--gdpr" };
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = { class: "row" };
var _hoisted_4 = { class: "col-sm-4" };
var _hoisted_5 = {
    key: 0,
    class: "col-sm-4"
};
var _hoisted_6 = { class: "col-sm-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["skagen-consent-general", { 'skagen-consent-overlay': !$props.blockViewMode }])
    }, [
        _createElementVNode("div", {
            class: _normalizeClass([
                $props.blockViewMode ? 'container' : 'container skagen-consent-container',
            ])
        }, [
            _createElementVNode("h2", null, _toDisplayString($props.consentTexts.heading), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", {
                    innerHTML: $props.consentTexts.generalMainIntro
                }, null, 8 /* PROPS */, _hoisted_2)
            ]),
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("button", {
                        class: "btn btn-block btn-primary",
                        onClick: _cache[0] || (_cache[0] = function ($event) { return ($options.acceptAll()); })
                    }, _toDisplayString($props.consentTexts.generalButtonAccept), 1 /* TEXT */)
                ]),
                ($options.showRejectButton)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("button", {
                            class: "btn btn-block btn-primary",
                            onClick: _cache[1] || (_cache[1] = function ($event) { return ($options.rejectAll()); })
                        }, _toDisplayString($props.consentTexts.generalButtonReject), 1 /* TEXT */)
                    ]))
                    : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("button", {
                        class: "btn btn-block btn-link",
                        onClick: _cache[2] || (_cache[2] = function ($event) { return ($options.showDetails()); })
                    }, _toDisplayString($props.consentTexts.generalButtonChange), 1 /* TEXT */)
                ])
            ])
        ], 2 /* CLASS */)
    ], 2 /* CLASS */));
}

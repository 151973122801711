var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Cookies from 'js-cookie';
import { getConsent } from '../Consent/consentUtil';
export var EventName;
(function (EventName) {
    EventName["GaEvent"] = "web-ga-event";
    EventName["PageView"] = "pageview";
})(EventName || (EventName = {}));
export var EventInfoCategory;
(function (EventInfoCategory) {
    EventInfoCategory["SavingsCalculator"] = "SavingsCalculator";
    EventInfoCategory["HistoricReturnCalculator"] = "HistoricReturnCalculator";
    EventInfoCategory["Withdrawal"] = "WithdrawalCalculator";
    EventInfoCategory["GobiBubbles"] = "GobiBubbles";
    EventInfoCategory["Consent"] = "Consent";
    EventInfoCategory["Newsletter"] = "Newsletter";
    EventInfoCategory["Podcast"] = "Podcast";
    EventInfoCategory["FundList"] = "FundList";
    EventInfoCategory["Campaign"] = "Campaign";
    EventInfoCategory["PerformanceCalculator"] = "PerformanceCalculator";
    EventInfoCategory["GenesysChat"] = "GenesysChat";
    EventInfoCategory["FundCostList"] = "FundCostList";
    EventInfoCategory["SkagenForceEvent"] = "SkagenForceEvent";
    EventInfoCategory["SliderContentBlock"] = "SliderContent";
    EventInfoCategory["PortfolioOverviewBlockApp"] = "PortfolioOverviewBlockApp";
})(EventInfoCategory || (EventInfoCategory = {}));
export var EventInfoAction;
(function (EventInfoAction) {
    EventInfoAction["Displayed"] = "Displayed";
    EventInfoAction["None"] = "None";
    EventInfoAction["Input"] = "Input";
    EventInfoAction["Button"] = "Button";
})(EventInfoAction || (EventInfoAction = {}));
var language = document.documentElement.getAttribute('lang') || 'no';
var environment = document.documentElement.getAttribute('data-environment') || 'development';
var market = document.documentElement.getAttribute('data-market') || 'no';
var brand = document.documentElement.getAttribute('data-brand') || 'SKAGEN';
var cipId = Cookies.get('CIP-ID');
var consent = getConsent();
var gtmInternalEventData = {
    environmentInfo: { brand: brand, environment: environment, language: language, market: market },
    userInfo: { id: cipId, loggedIn: !!cipId },
    consent: consent,
};
export var trackEvent = function (event) { return __awaiter(void 0, void 0, void 0, function () {
    var data, eventAsJson;
    return __generator(this, function (_a) {
        data = __assign(__assign({}, event), gtmInternalEventData);
        window.dataLayer.push(data);
        if (window.localStorage.getItem('webDebug') !== null) {
            eventAsJson = JSON.stringify(data);
            // eslint-disable-next-line no-console
            console.log('%c' + eventAsJson, 'color:#fff; background:#69f');
        }
        return [2 /*return*/];
    });
}); };
/**
 * Will track a predefined and open GA event.
 *
 * The event trigger in GTM is: web-ga-event.
 * The protocol is set ut to read:
 * eventInfo.category
 * eventInfo.action
 * eventInfo.title
 * eventInfo.value
 *
 * @param category - ends up as category in GA Event Tracking
 * @param action - ends up as action in GA Event Tracking
 * @param title - ends up as label in GA Event Tracking
 * @param value - ends up as value in GA Event Tracking
 */
export var trackTradidionalGaEvent = function (category, action, title, value) {
    trackEvent({
        event: EventName.GaEvent,
        eventInfo: {
            category: category,
            action: action,
            title: title,
            value: value,
        },
    });
};
export var trackPageView = function () {
    trackEvent({
        event: EventName.PageView,
    });
};
